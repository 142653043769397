import React, { useEffect, useState } from "react"
import Seo from "../../components/SEO"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useContextValues } from "../../context/NavBarContext"
import ProjectPortfolio from "../../components/DashboardPortfolio/PortfolioList"
import tickImage from "../../assets/img/green-tick.png"
import tickImageWhite from "../../assets/img/white-tick-bg.png"
import smileImage from "../../assets/img/smile.png"
import sadImage from "../../assets/img/sad-01.png"
import authorImage from "../../assets/img/author.png"
import arrowCirlceDown from "../../assets/img/arrow-circle-down.png"
import arrowCirlceUp from "../../assets/img/arrow-circle-up.png"
import ossie from "../../assets/img/ossie.png"
import gorkem from "../../assets/img/gorkem.png"
import diana from "../../assets/img/diana.png"

import LandingFooter from "../../components/PPC/Landing/LandingFooter"
import HeaderLanding from "../../components/PPC/Landing/HeaderLanding"
import callWhiteAnim from "../../assets/img/call-white.gif"
import callBlackAnim from "../../assets/img/call-black.gif"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faDribbble,
    faFacebook,
    faInstagram,
    faLinkedin,
    faTwitter,
} from "@fortawesome/free-brands-svg-icons"
import ModalLanding from "../../components/PPC/Landing/ModalLanding"
import ContactFormMVP from "../../components/PPC/Landing/ContactFormMVP"

const navList = [
    { text: "MVP Approach", link: "#mvp-approach" },
    { text: "Portfolio", link: "#portfolio" },
    { text: "MVP Process", link: "#mvp-process" },
    { text: "Testimonials", link: "#testimonial" },
    { text: "FAQs", link: "#faq" },
]
const LMSDevelopment = () => {
    const [showModal, setShowModal] = useState(false)
    const [selectedCheckboxId, setSelectedCheckboxId] = useState("")

    const {
        allMvpPortfoliosJson: { projects },
    } = useStaticQuery(graphql`
        query {
            allMvpPortfoliosJson {
                projects: nodes {
                idx
                slug
                featuredImage {
                    childImageSharp {
                    gatsbyImageData
                    }
                }
                backgroundStyles
                alt
                title
                subTitle
                industry
                budget
                country
                description
                clutchLink
                }
            }
        }
    `)

    const { changeCurrentPath, changeNavColor } = useContextValues()

    useEffect(() => {
        changeCurrentPath(location.pathname)
        changeNavColor("white")
        return (() => changeNavColor("black"))
    }, [])

    const handleFaqToggle = e => setSelectedCheckboxId(pre => pre === (e.target as any).id ? '' : (e.target as any).id)

    return (
        <>
            {/* Form modal starts */}
            <ModalLanding showModal={showModal} setShowModal={(val: boolean) => setShowModal(val)}>
                <ContactFormMVP
                    formTitle="Submit your accurate information"
                    submitBtnTxt="Submit"
                />
            </ModalLanding>
            {/* Form modal ends */}

            <HeaderLanding navList={navList} showContactButtonInWeb handleContactClick={() => setShowModal(true)} />

            <section id="contact" className="banner-gradient md:pt-[150px] md:pb-[70px] pb-[70px] lg:pb-[164px] bg-[#101111] relative before:content-[''] before:w-[56.5625rem] before:h-[56.5625rem] before:absolute before:bg-no-repeat before:top-[-20.625rem] before:right-[-18.75rem] z-[1] before:-z[-50] before:opacity-[0.3] before:blur-[5.625rem] overflow-hidden before:z-[-1] pt-[100px]">
                <div className="container">
                    <div className="flex flex-wrap ">
                        <h1 className="text-[32px] lg:text-[70px] leading-[40px] lg:leading-[90px] text-center text-white mx-auto mb-[2rem]">
                            <span className="block text-primary">Idea to MVP in 12 Weeks </span>
                            with Faster Iterations
                        </h1>
                        <p className="text-white md:text-xl text-base leading-[30px] text-center max-w-[970px] mx-auto">Our team of  <span className=" italic font-medium">Product Managers, UX Designers, Senior Developers, </span> and <span className=" italic font-medium">QA Specialists</span> collaborates closely with you to build a high-impact MVP for SaaS,  AI apps,  Agentic AI & Mobile apps in just 12 weeks using our Lean MVP Framework.</p>

                        <button
                            onClick={() => setShowModal(true)}
                            className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black  shadow-1xl text-base leading-[1.313rem] rounded-[8px] px-[1.75rem] py-[10px] font-gorditamedium group ease-in duration-300 hover:bg-primary max-md:mt-[15px] max-md:py-[5px] max-md:px-[20px] mt-5 md:mt-8 lg:mt-10 mx-auto"
                        >
                            <span className="hidden md:block mt-[0.125rem] group-hover:text-white transition-all ease-in duration-300">Get a Free 30-Minutes Consultation</span><span className="md:hidden group-hover:text-white mt-[0.125rem]">Book a Call</span>
                            <span className="inline-flex justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent max-sm:w-[2.1875rem] max-sm:h-[2.1875rem]">
                                <img src={callWhiteAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] opacity-0 invisible transition-all ease-in duration-300 group-hover:opacity-100 group-hover:visible  group-hover:text-white object-contain max-sm:top-[-3px]" />
                                <img src={callBlackAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] transition-all ease-in duration-300 group-hover:opacity-0 group-hover:invisible object-contain max-sm:top-[-3px]" />
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section className="pt-[50px] lg:pt-[110px] pb-[55px]">
                <div className="container">
                    <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center mb-[40px] md:mb-[50px]">MVPs Built for <span className="italic text-primary">Every Idea & Business</span></h2>
                    <div className="mvp-card grid md:grid-cols-2 lg:grid-cols-3 gap-[30px]">
                        <div className="bg-[#FAFAFA] border border-[#D1D1D1] rounded-[20px] p-[30px]">
                            <h3 className="text-black text-2xl md:text-[1.625rem] leading-[35px] font-medium mb-5">AI-Powered & SaaS MVPs</h3>
                            <ul>
                                <li className="text-base leading-[1.6875rem] text-black max-w-[246px] mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    AI-driven automation tools & platforms
                                </li>
                                <li className="text-base leading-[1.6875rem] text-black max-w-[246px] mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    SaaS platforms for B2B & B2C
                                </li>
                                <li className="text-base leading-[1.6875rem] text-black max-w-[246px] mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    Chatbots, NLP-based apps, AI analytics
                                </li>
                            </ul>
                        </div>

                        <div className="bg-[#FAFAFA] border border-[#D1D1D1] rounded-[20px] p-[30px]">
                            <h3 className="text-black text-2xl md:text-[1.625rem] leading-[35px] font-medium mb-5">Mobile & Web Apps</h3>
                            <ul>
                                <li className="text-base leading-[1.6875rem] text-black max-w-[246px] mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    Custom mobile & web applications
                                </li>
                                <li className="text-base leading-[1.6875rem] text-black max-w-[246px] mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    Marketplace platforms, social networking apps
                                </li>
                                <li className="text-base leading-[1.6875rem] text-black max-w-[246px] mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    Chatbots, NLP-based apps, AI analytics
                                </li>
                            </ul>
                        </div>

                        <div className="bg-[#FAFAFA] border border-[#D1D1D1] rounded-[20px] p-[30px]">
                            <h3 className="text-black text-2xl md:text-[1.625rem] leading-[35px] font-medium mb-5">Internal Business Apps</h3>
                            <ul>
                                <li className="text-base leading-[1.6875rem] text-black  mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    AI-powered internal dashboards & workflow automation
                                </li>
                                <li className="text-base leading-[1.6875rem] text-black  mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    CRM, inventory management, and productivity tools
                                </li>
                                <li className="text-base leading-[1.6875rem] text-black  mb-[11px] relative pl-[34px]">
                                    <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                        <img src={tickImage} alt="" className="object-cover w-full h-full" />
                                    </span>
                                    No-code & low-code business solutions
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section id="mvp-approach" className="approach-comparison pb-[50px] md:pb-[60px] lg:pb-[110px]">
                <div className="container">
                    <div className="title">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] pb-0 font-blancomedium text-center  mb-[50px]">Traditional <span>vs.</span> Our <span className="italic text-primary">Lean MVP Approach</span></h2>
                    </div>
                    <div className="grid md:grid-cols-2 gap-[30px]">
                        <div className="bg-[#F6F6F6] rounded-[20px] md:py-[30px] py-[20px] md:px-[40px] px-[30px]">
                            <h3 className="text-[24px] md:text-[28px] leading-[35px] font-medium mb-[30px]">Traditional Approach</h3>
                            <ul>
                                <li className="relative pl-[40px]">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={sadImage} alt="" className="w-full h-full object-cover" />
                                    </span>
                                    <p className="text-black text-xl leading-[27px] font-medium">6-12 Months to Launch</p>
                                    <p className="text-[#5D5D5D] text-sm font-semibold leading:[20px] md:leading-[30px]">Endless planning and development cycles</p>
                                    <span className="text-[#5D5D5D] text-[10px] italic font-medium inline-block py-[0.1875rem] px-[0.75rem] bg-white-200 rounded-[44px] mt-[4px]">Can’t sure time delivery</span>
                                </li>
                                <li className="relative pl-[40px] mt-5">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={sadImage} alt="" />
                                    </span>
                                    <p className="text-black text-xl leading-[27px] font-medium">$50,000+ Investment</p>
                                    <p className="text-[#5D5D5D] text-sm font-semibold leading:[20px] md:leading-[30px]">Heavy upfront costs before seeing results</p>
                                    <span className="text-[#5D5D5D] text-[10px] italic font-medium inline-block py-[0.1875rem] px-[0.75rem] bg-white-200 rounded-[44px] mt-[4px]">No limit on investment</span>
                                </li>
                                <li className="relative pl-[40px] mt-5">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={sadImage} alt="" />
                                    </span>
                                    <p className="text-black text-xl leading-[27px] font-medium">Complex Tech Choices</p>
                                    <p className="text-[#5D5D5D] text-sm font-semibold leading:[20px] md:leading-[30px]">Overwhelmed by technical choices and debates</p>
                                    <span className="text-[#5D5D5D] text-[10px] italic font-medium inline-block py-[0.1875rem] px-[0.75rem] bg-white-200 rounded-[44px] mt-[4px]">add something related</span>
                                </li>
                                <li className="relative pl-[40px] mt-5">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={sadImage} alt="" />
                                    </span>
                                    <p className="text-black text-xl leading-[27px] font-medium">Multiple Developers</p>
                                    <p className="text-[#5D5D5D] text-sm font-semibold leading:[20px] md:leading-[30px]">Endless planning and development cycles</p>
                                    <span className="text-[#5D5D5D] text-[10px] italic font-medium inline-block py-[0.1875rem] px-[0.75rem] bg-white-200 rounded-[44px] mt-[4px]">add something related</span>
                                </li>
                            </ul>
                        </div>

                        <div className="bg-[#101111] rounded-[20px] md:py-[30px] py-[20px] md:px-[40px] px-[30px] border border-[#399C14] relative">
                            <span className="inline-block text-xs text-white pt-[8px] pb-[6px] px-[15px] bg-[#399C14] rounded-[50px] absolute left-[50px] top-[-15px]">Top choice</span>
                            <h3 className="font-medium mb-[30px] text-white text-[24px] md:text-[28px] leading-[35px]">Techuz MVP Approach</h3>
                            <ul>
                                <li className="relative pl-[40px]">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={smileImage} alt="" className="w-full h-full object-cover" />
                                    </span>
                                    <p className="text-white text-xl leading-[27px] font-medium">12 Weeks to Market</p>
                                    <p className="text-[#D1D1D1] text-sm font-semibold leading:[20px] md:leading-[30px] my-[8px]">Focused development of core features</p>
                                    <span className="text-[#fff] text-[10px] italic font-medium inline-block py-[3px] px-[0.75rem] bg-[#343434] rounded-[44px] mt-[4px]">95% on-time delivery</span>
                                </li>
                                <li className="relative pl-[40px] mt-5">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={smileImage} alt="" />
                                    </span>
                                    <p className="text-white text-xl leading-[27px] font-medium">Fixed Transparent Price</p>
                                    <p className="text-[#D1D1D1] text-sm font-semibold leading:[20px] md:leading-[30px] my-[8px]">No surprises, just results you can count on </p>
                                    <span className="text-[#fff] text-[10px] italic font-medium inline-block py-[3px] px-[0.75rem] bg-[#343434] rounded-[44px] mt-[4px]">50+ successful deploys</span>
                                </li>
                                <li className="relative pl-[40px] mt-5">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={smileImage} alt="" />
                                    </span>
                                    <p className="text-white text-xl leading-[27px] font-medium">Pre-built Foundation</p>
                                    <p className="text-[#D1D1D1] text-sm font-semibold leading:[20px] md:leading-[30px] my-[8px]">Production-ready tech stack, ready to go</p>
                                    <span className="text-[#fff] text-[10px] italic font-medium inline-block py-[3px] px-[0.75rem] bg-[#343434] rounded-[44px] mt-[4px]">50+ successful deploys</span>
                                </li>
                                <li className="relative pl-[40px] mt-5">
                                    <span className="inline-block w-[24px] h-[24px] absolute left-0 top-0">
                                        <img src={smileImage} alt="" />
                                    </span>
                                    <p className="text-white text-xl leading-[27px] font-medium">Dedicated Expert Team</p>
                                    <p className="text-[#D1D1D1] text-sm font-semibold leading:[20px] md:leading-[30px] my-[8px]">One point of contact, full accountability</p>
                                    <span className="text-[#fff] text-[10px] italic font-medium inline-block py-[3px] px-[0.75rem] bg-[#343434] rounded-[44px] mt-[4px]">24/7 support</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>


            <section id="portfolio" className="support-sec pt-[40px] md:pt-[56px] lg:pt-[90px] py-14 relative home-page-portfolio bg-[#0E2C07] pb-[30px] md:pb-[50px] lg:pb-[110px]">
                <div className="container">
                    <div className="w-full title-sec max-sm:pb-6 pb-12">
                        <h2 className="text-white text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center">
                            <span className="text-primary italic">Successful MVPs</span> We've Built<span className="inline-block text-orangecol">.</span>
                        </h2>
                    </div>
                    <ProjectPortfolio projectList={projects} showBudget={false} />
                </div>
            </section>


            <section className="py-[30px] lg:py-[110px]  md:py-[50px]">
                <div className="container">
                    <div className="bg-[#0E2C07] rounded-[20px] py-[80px] flex flex-wrap justify-center flex-col">
                        <h2 className="text-white text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center md:max-w-[665px] mx-auto mb-[1.5rem]">
                            Over <span className="text-primary italic">50+ successful MVP launches</span> in multiple industries
                            <span className="inline-block text-orangecol">.</span>
                        </h2>
                        <p className="text-center  inline-block md:mb-[47px] mb-[37px]">
                            <span className="inline-block text-white font-blancomedium text-2xl leading-[24px] font-semibold relative">
                                Isn’t it great
                                <span className="title-decor w-[55px] h-[55px] inline-block  max-[767px]:right-[-3.125rem] absolute top-[-8px] right-[-50px]">
                                    <StaticImage
                                        src="../../assets/img/gold-star.webp"
                                        alt="Star"
                                        placeholder="blurred"
                                        className="w-full h-full object-cover"
                                    />
                                </span>
                            </span>
                        </p>
                        <button
                            onClick={() => setShowModal(true)}
                            className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black  shadow-1xl text-base leading-[1.313rem] rounded-[8px] px-[1.75rem] py-[10px] font-gorditamedium group ease-in duration-300 hover:bg-primary max-md:mt-[15px] max-md:py-[5px] max-md:px-[20px]  mx-auto"
                        >
                            <span className="hidden md:block mt-[0.125rem] group-hover:text-white transition-all ease-in duration-300">Get a Free 30-Minutes Consultation</span><span className="md:hidden group-hover:text-white mt-[0.125rem]">Book a Call</span>
                            <span className="inline-flex justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent max-sm:w-[2.1875rem] max-sm:h-[2.1875rem]">
                                <img src={callWhiteAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] opacity-0 invisible transition-all ease-in duration-300 group-hover:opacity-100 group-hover:visible  group-hover:text-white object-contain max-sm:top-[-3px]" />
                                <img src={callBlackAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] transition-all ease-in duration-300 group-hover:opacity-0 group-hover:invisible object-contain max-sm:top-[-3px]" />
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section id="mvp-process" className="bg-[#F6F6F6] lg:pt-[110px] lg:pb-[160px] md:pt-[50px] md:pb-[60px] py-[35px]">
                <div className="container">
                    <div className="title">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center md:max-w-[665px] mx-auto mb-[45px]">
                            <span className="italic">Our</span> Lean <span className="italic">MVP Framework </span>
                        </h2>
                    </div>


                    <div className="bg-[#FFDFB9] rounded-[20px] p-[30px] relative mb-[3.125rem]">
                        <span className="inline-block text-xs text-white pt-[8px] pb-[6px] px-[15px] bg-[#399C14] rounded-[50px] absolute left-[50px] top-[-15px]">Weeks 1-2</span>
                        <h3 className="text-black text-[1.625rem] leading-[35px] font-medium mb-5">Product Discovery & Prototyping</h3>
                        <ul>
                            <li className="text-base leading-[1.6875rem] text-black mb-[11px] relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Define  core MVP features (not unnecessary extras)
                            </li>
                            <li className="text-base leading-[1.6875rem] text-black mb-[11px] relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Create a high-fidelity prototype for early validation.
                            </li>
                            <li className="text-base leading-[1.6875rem] text-black relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Gather real user feedback before writing a single line of code.
                            </li>
                        </ul>
                    </div>

                    <div className="bg-[#A8FFBC] rounded-[20px] p-[30px] relative mb-[3.125rem]">
                        <span className="inline-block text-xs text-white pt-[8px] pb-[6px] px-[15px] bg-[#399C14] rounded-[50px] absolute left-[50px] top-[-15px]">Weeks 3-11</span>
                        <h3 className="text-black text-[1.625rem] leading-[35px] font-medium mb-5">Lean Agile Development</h3>
                        <ul>
                            <li className="text-base leading-[1.6875rem] text-black mb-[11px] relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Rapid development using React, Node.js, Python, and AI-driven tools.
                            </li>
                            <li className="text-base leading-[1.6875rem] text-black mb-[11px] relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Weekly sprints to ensure continuous progress.
                            </li>
                            <li className="text-base leading-[1.6875rem] text-black relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Integrated QA & testing to eliminate launch delays
                            </li>
                        </ul>
                    </div>

                    <div className="bg-[#ABF9FF] rounded-[20px] p-[30px] relative">
                        <span className="inline-block text-xs text-white pt-[8px] pb-[6px] px-[15px] bg-[#399C14] rounded-[50px] absolute left-[50px] top-[-15px]">Week 12</span>
                        <h3 className="text-black text-[1.625rem] leading-[35px] font-medium mb-5">Go-to-Market & Growth</h3>
                        <ul>
                            <li className="text-base leading-[1.6875rem] text-black mb-[11px] relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Deploy on AWS, GCP, or Azure with full scalability.
                            </li>
                            <li className="text-base leading-[1.6875rem] text-black mb-[11px] relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Set up analytics & performance tracking.
                            </li>
                            <li className="text-base leading-[1.6875rem] text-black relative pl-[34px]">
                                <span className="absolute left-0 top-[50%] translate-y-[-50%] w-5 h-5">
                                    <img src={tickImageWhite} alt="" className="object-cover w-full h-full" />
                                </span>
                                Deliver marketing & investor-ready assets (pitch deck, reports, demo video)
                            </li>
                        </ul>
                    </div>

                    <div className="flex flex-wrap justify-center">
                        <button
                            onClick={() => setShowModal(true)}
                            className="inline-flex w-auto gap-[0.688rem] items-center bg-[#399C14] justify-center md:justify-start inline-white text-black  shadow-1xl text-base leading-[1.313rem] rounded-[8px] px-[1.75rem] py-[10px] font-gorditamedium group ease-in duration-300 hover:bg-white-200 max-md:mt-[15px] max-md:py-[5px] max-md:px-[20px] mt-5 md:mt-8 lg:mt-10 mx-auto"
                        >
                            <span className="hidden md:block mt-[0.125rem] group-hover:text-black text-white transition-all ease-in duration-300">Get a Free 30-Minutes Consultation</span><span className="md:hidden group-hover:text-black text-white mt-[0.125rem]">Book a Call</span>
                            <span className="inline-flex justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 bg-[#399C14] group-hover:bg-white-200 group-hover:bg-transparent border-2 border-transparent max-sm:w-[2.1875rem] max-sm:h-[2.1875rem]">
                                <img src={callWhiteAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] transition-all ease-in duration-300 group-hover:opacity-100 group-hover:visible  group-hover:text-white object-contain max-sm:top-[-3px]" />
                                <img src={callBlackAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] transition-all ease-in duration-300 object-contain max-sm:top-[-3px] invisible group-hover:opacity-1 group-hover:visible " />
                            </span>
                        </button>
                    </div>
                </div>
            </section>

            <section id="testimonial" className="pt-[60px] lg:pt-[110px] pb-[60px] lg:pb-[130px]">
                <div className="container">
                    <div className="title">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium text-center md:max-w-[785px] mx-auto mb-[45px]">
                            Discover the stories of our <span className="italic text-primary">satisfied clients</span>
                        </h2>
                    </div>
                    <div className="review-card-grid grid lg:grid-cols-2 gap-[30px]">
                        <div className="review-card bg-[#F8F7F2] p-[30px] rounded-[20px]">
                            <p className="text-lg leading-[30px] text-black mb-[30px]">Techuz played a crucial role in building our MVP, revolutionizing our order processing efficiency. What once took 24 hours now takes just 15 minutes! The team was communicative, delivered high-quality work on time, and helped us streamline operations effortlessly."</p>
                            <div className="author grid grid-cols-[60px_1fr] gap-[10px] items-center">
                                <span className="inline-block w-[60px] h-[60px] rounded-full">
                                    <img src={authorImage} alt="" className="w-full h-full rounded-full" />
                                </span>
                                <div>
                                    <p className="text-black text-xl font-medium mb-[6px] leading-none">Alexa</p>
                                    <span className="text-[#5D5D5D] text-sm font-medium">Transforming Custom Measurements in Fashion & Retail</span>
                                </div>
                            </div>
                        </div>

                        <div className="review-card bg-[#F8F7F2] p-[30px] rounded-[20px]">
                            <p className="text-lg leading-[30px] text-black mb-[30px]">Techuz rapidly built multiple web app solutions, allowing us to launch our services faster. Their speed and efficiency had a direct impact on increasing our service bookings in the skydiving industry. Highly recommend them for fast-paced product development!</p>
                            <div className="author grid grid-cols-[60px_1fr] gap-[10px] items-center">
                                <span className="inline-block w-[60px] h-[60px] rounded-full">
                                    <img src={ossie} alt="" className="w-full h-full rounded-full" />
                                </span>
                                <div>
                                    <p className="text-black text-xl font-medium mb-[6px] leading-none">Ossie</p>
                                    <span className="text-[#5D5D5D] text-sm font-medium">Accelerating Market Entry for Skydiving Services</span>
                                </div>
                            </div>
                        </div>

                        <div className="review-card bg-[#F8F7F2] p-[30px] rounded-[20px]">
                            <p className="text-lg leading-[30px] text-black mb-[30px]">Vaibhav and the Techuz team helped us develop a powerful plugin for our SaaS analytical tool, enabling us to offer valuable add-ons to our platform users which Improved user management and a more robust product experience!</p>
                            <div className="author grid grid-cols-[60px_1fr] gap-[10px] items-center">
                                <span className="inline-block w-[60px] h-[60px] rounded-full">
                                    <img src={gorkem} alt="" className="w-full h-full rounded-full" />
                                </span>
                                <div>
                                    <p className="text-black text-xl font-medium mb-[6px] leading-none">Gorkem</p>
                                    <span className="text-[#5D5D5D] text-sm font-medium">Enhancing SaaS User Management with a Custom Plugin</span>
                                </div>
                            </div>
                        </div>

                        <div className="review-card bg-[#F8F7F2] p-[30px] rounded-[20px]">
                            <p className="text-lg leading-[30px] text-black mb-[30px]">Techuz excels at understanding micro-details of our mortgage business. They efficiently launched our MVP while strategically keeping additional features for later phases. Their expertise and structured approach made the process seamless. Simply superb and highly recommended!</p>
                            <div className="author grid grid-cols-[60px_1fr] gap-[10px] items-center">
                                <span className="inline-block w-[60px] h-[60px] rounded-full">
                                    <img src={diana} alt="" className="w-full h-full rounded-full" />
                                </span>
                                <div>
                                    <p className="text-black text-xl font-medium mb-[6px] leading-none">Diana</p>
                                    <span className="text-[#5D5D5D] text-sm font-medium">Precision & Speed in Mortgage Tech Development</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faq" className="mvp-faq pb-10 md:pb-[60px] lg:pb-[110px]">
                <div className="container">
                    <div className="faq-wrapper max-w-[48rem] mx-auto">
                        <h2 className="text-black text-3xl lg:text-[2.625rem] lg:leading-[3.375rem] font-blancomedium mx-auto mb-[24px]">Frequently <span className="text-primary italic">Asked Questions</span></h2>

                        <div>
                            <div className="mx-auto overflow-hidden">
                                <div className="border-b border-b-[#E7E7E7] py-8">
                                    <input type="checkbox" name="tailwind_accordion" id="tab1" className="accordion__input px-2 py-4 hidden"
                                        checked={selectedCheckboxId === "tab1"}
                                        onClick={handleFaqToggle}
                                    />
                                    <label htmlFor="tab1" className="accordion__label text-black text-lg leading-[1.55] font-medium cursor-pointer block relative pr-[24px]">Why should I build an MVP instead of a full product?
                                        <span className="inline-block absolute right-0 top-0 close">
                                            <img src={arrowCirlceDown}></img>
                                        </span>

                                        <span className="absolute right-0 top-0 hidden open">
                                            <img src={arrowCirlceUp}></img>
                                        </span>
                                    </label>
                                    <div className="accordion__content bg-white leading-relaxed hidden pt-[1rem]">
                                        <p className="text-base leading-[30px]">An MVP (Minimum Viable Product) allows you to test your idea in the market with minimal investment. Instead of spending months (or years) developing a full product that may not meet user needs, an MVP helps you:
                                        </p>
                                        <ul className="py-5">
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">Validate your business idea with real users.</li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">Get early feedback and make improvements.</li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">Reduce time-to-market and start acquiring customers sooner.</li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">Attract investors by showing traction.</li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">Save costs by focusing only on essential features.</li>
                                        </ul>
                                        <p>By launching an MVP first, you can pivot, refine, or scale your product based on actual market demand rather than assumptions.
                                        </p>
                                    </div>
                                </div>

                                <div className="border-b border-b-[#E7E7E7] py-8">
                                    <input type="checkbox" name="tailwind_accordion" id="tab2" className="accordion__input px-2 py-4 hidden"
                                        checked={selectedCheckboxId === "tab2"}
                                        onClick={handleFaqToggle}
                                    />
                                    <label htmlFor="tab2" className="accordion__label text-black text-lg leading-[1.55] font-medium cursor-pointer block relative pr-[24px]">How much does MVP development typically cost?
                                        <span className="inline-block absolute right-0 top-0 close">
                                            <img src={arrowCirlceDown}></img>
                                        </span>

                                        <span className="absolute right-0 top-0 hidden open">
                                            <img src={arrowCirlceUp}></img>
                                        </span>
                                    </label>
                                    <div className="accordion__content bg-white leading-relaxed pt-[1rem] hidden">
                                        <p>MVP development costs vary depending on the complexity of features, technology stack, and team expertise. Generally:</p>
                                        <ul className="py-5">
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>Simple MVPs</strong> start from <strong>$10000 - $20,000.</strong>
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong> Moderate complexity MVPs</strong> (custom web & mobile apps) range from  <strong>$25,000 - $50,000.</strong>
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0"><strong> Complex MVPs</strong> (AI/ML features, multi-platform integrations) can go beyond  <strong>$50,000+</strong></li>
                                        </ul>
                                        <p>We tailor our approach to match your budget and business goals while ensuring a high-quality, scalable MVP.</p>
                                    </div>
                                </div>

                                <div className="border-b border-b-[#E7E7E7] py-8">
                                    <input type="checkbox" name="tailwind_accordion" id="tab3" className="accordion__input px-2 py-4 hidden"
                                        checked={selectedCheckboxId === "tab3"}
                                        onClick={handleFaqToggle}
                                    />
                                    <label htmlFor="tab3" className="accordion__label text-black text-lg leading-[1.55] font-medium cursor-pointer block relative pr-[24px]">Do you offer fixed-price or hourly pricing for MVP development?
                                        <span className="inline-block absolute right-0 top-0 close">
                                            <img src={arrowCirlceDown}></img>
                                        </span>

                                        <span className="absolute right-0 top-0 hidden open">
                                            <img src={arrowCirlceUp}></img>
                                        </span>
                                    </label>
                                    <div className="accordion__content bg-white leading-relaxed pt-[1rem] hidden">
                                        <p>We offer both <strong>fixed-price</strong> and <strong>hourly</strong> pricing models:</p>
                                        <ul className="py-5">
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>Fixed-Price:</strong>  Best for projects with well-defined scope and requirements. Ideal if you want cost certainty.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong> Hourly (Time & Material)</strong>  Best for evolving projects where flexibility is needed. You pay only for the time spent.
                                            </li>

                                        </ul>
                                        <p>We also provide a <strong>discovery phase</strong> where we refine the scope and suggest the best pricing model for your MVP.</p>
                                    </div>
                                </div>

                                <div className="border-b border-b-[#E7E7E7] py-8">
                                    <input type="checkbox" name="tailwind_accordion" id="tab4" className="accordion__input px-2 py-4 hidden"
                                        checked={selectedCheckboxId === "tab4"}
                                        onClick={handleFaqToggle}
                                    />
                                    <label htmlFor="tab4" className="accordion__label text-black text-lg leading-[1.55] font-medium cursor-pointer block relative pr-[24px]">Can I scale my MVP into a full-fledged product later?
                                        <span className="inline-block absolute right-0 top-0 close">
                                            <img src={arrowCirlceDown}></img>
                                        </span>

                                        <span className="absolute right-0 top-0 hidden open">
                                            <img src={arrowCirlceUp}></img>
                                        </span>
                                    </label>
                                    <div className="accordion__content bg-white leading-relaxed pt-[1rem] hidden">
                                        <p>Yes! We build MVPs with scalability in mind, ensuring that:</p>
                                        <ul className="py-5">
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                The <strong>architecture</strong> supports future growth.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                The <strong>codebase</strong> is maintainable and extendable.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                The <strong>design and UX</strong> are adaptable for new features.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                The <strong>technology stack</strong> allows seamless scaling.
                                            </li>

                                        </ul>
                                        <p>Once your MVP gains traction, we can help you transition into a full product by adding advanced features, optimizing performance, and ensuring a smooth user experience.</p>
                                    </div>
                                </div>

                                <div className="border-b border-b-[#E7E7E7] py-8">
                                    <input type="checkbox" name="tailwind_accordion" id="tab5" className="accordion__input px-2 py-4 hidden"
                                        checked={selectedCheckboxId === "tab5"}
                                        onClick={handleFaqToggle}
                                    />
                                    <label htmlFor="tab5" className="accordion__label text-black text-lg leading-[1.55] font-medium cursor-pointer block relative pr-[24px]">What technologies do you use to build MVPs?
                                        <span className="inline-block absolute right-0 top-0 close">
                                            <img src={arrowCirlceDown}></img>
                                        </span>

                                        <span className="absolute right-0 top-0 hidden open">
                                            <img src={arrowCirlceUp}></img>
                                        </span>
                                    </label>
                                    <div className="accordion__content bg-white leading-relaxed pt-[1rem] hidden">
                                        <p>We choose the best technology stack based on your business needs, speed-to-market, and scalability. Our <strong>MVP development</strong> typically includes:</p>
                                        <ul className="py-5">
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>React.js, Next.js</strong> – For fast and interactive web applications.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>Node.js (Express, NestJS)</strong> – Fast and scalable backend for real-time apps.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>Python (Django, FastAPI, Flask)</strong> – AI-driven and data-intensive applications.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>Flutter, React Native</strong> – For cross-platform mobile apps (iOS & Android).
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>AWS, Google Cloud, Firebase</strong> – Scalable cloud hosting and infrastructure.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>Bubble, Webflow</strong> – For no-code web apps.
                                            </li>
                                            <li className="relative pl-[15px] before:content-[''] before:w-[6px] before:h-[6px] before:absolute before:rounded-full before:bg-black before:top-[8px] before:left-0">
                                                <strong>OpenAI, LangChain</strong> – AI-powered features & chatbots.
                                            </li>


                                        </ul>
                                        <p>Our approach ensures we select the <strong>most efficient, cost-effective, and scalable</strong> stack based on your business requirements.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >

            <section className="bg-[#0E2C07] py-[3.125rem] lg:py-[5rem]">
                <div className="title">
                    <h2 className="text-white text-3xl lg:text-[60px] lg:leading-[90px] font-blancomedium text-center md:max-w-[860px] mx-auto">
                        Get the real estimates and <br></br><span className="italic text-primary">ideal solutions</span> for your project.
                    </h2>
                    <div className="flex flex-wrap justify-center">
                        <button
                            onClick={() => setShowModal(true)}
                            className="inline-flex w-auto gap-[0.688rem] items-center bg-white-200 justify-center md:justify-start inline-white text-black  shadow-1xl text-base leading-[1.313rem] rounded-[8px] px-[1.75rem] py-[10px] font-gorditamedium group ease-in duration-300 hover:bg-primary max-md:mt-[15px] max-md:py-[5px] max-md:px-[20px] mt-5 md:mt-8 lg:mt-10 mx-auto"
                        >
                            <span className="hidden md:block mt-[0.125rem] group-hover:text-white transition-all ease-in duration-300">Get a Free 30-Minutes Consultation</span><span className="md:hidden group-hover:text-white mt-[0.125rem]">Book a Call</span>
                            <span className="inline-flex justify-center items-center rounded-full relative h-10 w-10 overflow-hidden ease-in duration-300 group-hover:bg-primary group-hover:bg-transparent border-2 border-transparent max-sm:w-[2.1875rem] max-sm:h-[2.1875rem]">
                                <img src={callWhiteAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] opacity-0 invisible transition-all ease-in duration-300 group-hover:opacity-100 group-hover:visible  group-hover:text-white object-contain max-sm:top-[-3px]" />
                                <img src={callBlackAnim} alt="Get a Free 30-Minutes Consultation" className="w-[2.813rem] h-[2.188rem] absolute top-[0px] left-[2px] transition-all ease-in duration-300 group-hover:opacity-0 group-hover:invisible object-contain max-sm:top-[-3px]" />
                            </span>
                        </button>

                        <ul className="flex items-center w-full justify-center mt-[32px]">
                            <li>
                                <a
                                    href="https://www.facebook.com/TechuzIT"
                                    rel="dofollow"
                                    target="_blank"
                                    aria-label="Facebook"
                                    className="text-[#285E16] text-base lg:mr-5 mr-3 hover:text-primary transition-all duration-500"
                                >
                                    <FontAwesomeIcon icon={faFacebook} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.twitter.com/TechuzIT"
                                    rel="dofollow"
                                    target="_blank"
                                    aria-label="Twitter"
                                    className="text-[#285E16] text-base lg:mr-5 mr-3 hover:text-primary transition-all duration-500"
                                >
                                    <FontAwesomeIcon icon={faTwitter} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://in.linkedin.com/company/techuz-infoweb-pvt-ltd"
                                    rel="dofollow"
                                    target="_blank"
                                    aria-label="LinkedIn"
                                    className="text-[#285E16] text-base lg:mr-5 mr-3 hover:text-primary transition-all duration-500"
                                >
                                    <FontAwesomeIcon icon={faLinkedin} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://dribbble.com/techuz"
                                    rel="dofollow"
                                    target="_blank"
                                    aria-label="Dribbble"
                                    className="text-[#285E16] text-base lg:mr-5 mr-3 hover:text-primary transition-all duration-500"
                                >
                                    <FontAwesomeIcon icon={faDribbble} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/techuz/"
                                    rel="dofollow"
                                    target="_blank"
                                    aria-label="Instagram"
                                    className="text-[#285E16] text-base lg:mr-5 mr-3 hover:text-primary transition-all duration-500"
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <LandingFooter mainSecClass="custom-landing-footer" showLove showForm={false} showSocialSection={false} />
        </>
    )
}
export const Head = () => <Seo title="MVP Development" noIndex />

export default LMSDevelopment